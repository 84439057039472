import { Pipe, PipeTransform } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { Article } from '@lobos/common-v3';
import { ImageHelperService } from '../services/catalog/image-helper.service';

@Pipe({
  name: 'articleMainImage',
  standalone: true,
})
export class ArticleMainImagePipe implements PipeTransform {
  constructor(private imageHelperService: ImageHelperService) {}

  transform(article: Article | undefined | null): CloudinaryImage {
    return article
      ? this.imageHelperService.getArticleMainImage(article) || this.imageHelperService.returnFallbackImage()
      : this.imageHelperService.returnFallbackImage();
  }
}
